<template>
    <div>
        <div>
            <TrailerOrder_Component></TrailerOrder_Component>
        </div>
    </div>
</template>

<script>
import TrailerOrder_Component from '../../components/PersonalCenter/TrailerOrder_Component.vue';
export default {
    name: "MyOrder",
    components: { TrailerOrder_Component }
}
</script>

<style lang="scss" scoped>

</style>